export const guest = {
  state: { guestSuccess: [], successfulGuestData: Object(), guestPaymentData: Object(), delegateData: Object(), guestCheckinData: Object() },
  reducers: {
    fetchStart: (state, { guestSuccessfulScreen }) => ({
      ...state,
      guestSuccess: guestSuccessfulScreen,
    }),
    setGuestSuccessData: (state, param) => ({ ...state, successfulGuestData: param }),
    setGuestPaymentData: (state, data) => ({ ...state, guestPaymentData: data }),
    setDelegateData: (state, data) => ({ ...state, delegateData: data }),
    clearDelegateData: state => ({ ...state, delegateData: Object() }),
    clearGuestSuccess: state => ({ ...state, successfulGuestData: Object(), guestSuccess: [] }),
    setGuestCheckin: (state, data) => ({ ...state, guestCheckinData: data}),
  },
};
