import React, { useReducer, memo, useEffect } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { Header } from '..';
import { DayPickerRangeController } from 'react-dates';
import moment from 'moment';
import classNames from 'classnames';
import { store } from '../../../models';
import styles from './dateRangePicker.module.scss';
import {
  START_DATE,
  END_DATE,
  MAX_MONTHS_OUT,
  MAX_DATE_RANGE,
  MIN_DATE_RANGE,
  CHOOSE_DATE_ID,
  CALENDAR_ICON_ID,
  ICON_URL,
  START_DATE_ID,
  END_DATE_ID,
  LEFT_ARROW_URL,
  RIGHT_ARROW_URL,
  DAY_SIZE,
  WEEKDAY_FORMAT,
} from '../../../constants/DatePicker';

const DateRangePickerContainer = ({ id, labels }) => {
  const initialDatePickerState = {
    startDate: moment(12, "HH"),
    endDate: moment(12, "HH")
      .add(2, 'days'),
    focusedInput: START_DATE,
    datePickerOpen: false,
  };
  const datePickerReducer = (state, payload) => ({ ...state, ...payload });
  const [state, dispatch] = useReducer(datePickerReducer, initialDatePickerState);
  const ousideClickHandler = () => {
    if (store.getState().addAppointment.startDate && store.getState().addAppointment.endDate) {
      return dispatch({
        startDate: store.getState().addAppointment.startDate,
        endDate: store.getState().addAppointment.endDate,
        datePickerOpen: false,
      });
    } else {
      dispatch({ ...initialDatePickerState });
    }
  };
  useEffect(() => {
    if (store.getState().addAppointment.startDate && store.getState().addAppointment.endDate) {
      return dispatch({
        startDate: store.getState().addAppointment.startDate,
        endDate: store.getState().addAppointment.endDate,
      });
    }
  }, []);

  return (
    <div className={styles.wrapper}>
      <button className={styles.iconButton} onClick={_ => dispatch({ datePickerOpen: !state.datePickerOpen })}>
        <Header
          {...{
            id: CHOOSE_DATE_ID,
            theme: CHOOSE_DATE_ID,
            type: 'h5',
            label: labels.chooseADifferentDate,
          }}
        />
        <Header
          {...{
            id: CALENDAR_ICON_ID,
            theme: CALENDAR_ICON_ID,
            icon: ICON_URL,
            type: 'h6',
          }}
        />
      </button>
      <div className={classNames(styles.calendarWrapper, { datePickerHidden: !state.datePickerOpen })}>
        <DayPickerRangeController
          hidden
          id={id}
          onOutsideClick={ousideClickHandler}
          numberOfMonths={1}
          minimumNights={MIN_DATE_RANGE}
          enableOutsideDays
          startDateId={START_DATE_ID}
          endDateId={END_DATE_ID}
          startDate={state.startDate}
          endDate={state.endDate}
          onDatesChange={({ startDate, endDate }) => {
            if (state.startDate && state.endDate && endDate > state.endDate) {
              return dispatch({ startDate: endDate, endDate: null });
            }
            return dispatch({ startDate, endDate });
          }}
          focusedInput={state.focusedInput}
          onFocusChange={focusedInput => dispatch({ focusedInput })}
          initialVisibleMonth={() => moment()}
          isOutsideRange={day =>
            day.isBefore(moment().startOf('day')) ||
            day.isAfter(
              moment()
                .startOf('day')
                .clone()
                .add(MAX_MONTHS_OUT, 'months'),
            ) ||
            (state.focusedInput === END_DATE && day.isAfter(state.startDate.clone().add(MAX_DATE_RANGE, 'days')))
          }
          keepOpenOnDateSelect
          hideKeyboardShortcutsPanel
          navPrev={<img alt="icon" src={LEFT_ARROW_URL} />}
          navNext={<img alt="icon" src={RIGHT_ARROW_URL} />}
          weekDayFormat={WEEKDAY_FORMAT}
          daySize={DAY_SIZE}
          renderCalendarInfo={_ => (
            <div className={styles.buttonsWrapper}>
              <button
                className={styles.nextThreeBtn}
                onClick={_ => {
                  let initialDate = moment(state.endDate)
                    .add(1, 'days')
                    .startOf('day');
                  let finalDate = moment(initialDate)
                    .add(2, 'days')
                    .endOf('day');
                  if (store.getState().addAppointment.startDate && store.getState().addAppointment.endDate) {
                    console.log('inside');
                    initialDate = moment(store.getState().addAppointment.endDate)
                      .add(1, 'days')
                      .startOf('day');
                    finalDate = moment(initialDate)
                      .add(2, 'days')
                      .endOf('day');
                  }
                  dispatch({ datePickerOpen: false });
                  store.dispatch.loader.persistLoader({ persist: true });
                  store.dispatch.addAppointment.selectDate({
                    startDate: initialDate,
                    endDate: finalDate,
                  });
                  store.dispatch.availableHours.resetState();
                }}
              >
                {labels.nextThree}
              </button>
              <button
                className={styles.applyBtn}
                onClick={_ => {
                  const { startDate, endDate } = state;
                  store.dispatch.loader.persistLoader({ persist: true });
                  dispatch({ datePickerOpen: false });
                  store.dispatch.addAppointment.selectDate({ startDate, endDate });
                  store.dispatch.datePicker.selectDate({
                    id,
                    value: { startDate, endDate },
                  });
                }}
              >
                {labels.apply}
              </button>
            </div>
          )}
          readOnly
        />
      </div>
    </div>
  );
};
// DateRangePickerContainer.whyDidYouRender = true;

export default memo(DateRangePickerContainer);
