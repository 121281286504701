import React, { useReducer } from 'react';
import styled from 'styled-components';
import { withApollo } from 'react-apollo';
import { Button, FormField, SearchBoxField, Text } from '../../styleguide';
import { SearchPhysicians } from '../../../graphql/queries';
import PhysicianCard from './PhysicianCard';
import { PageLoader } from '../../ui/page_loader/pageLoader';
import { fullName, PAGE_SIZE } from './Physician.helper';
import { store } from '../../../models';

const PrimaryContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid #cfd8dc;
`;

const SecondaryContent = styled.div`
  margin: 0 0 16px;
`;

const Actions = styled.div`
  padding: 16px;
`;

const reducer = (state, payload) => ({ ...state, ...payload });

const Component = React.memo(props => {
  const {
    client,
    required,
    formData,
    onChange,
    schema: {
      applyText,
      cancelText,
      chooseText,
      queryPlaceholderText,
      statePlaceholderText,
      placeholderText,
      title,
      states,
      detailsText,
      viewMoreText,
      endOfResultsText,
    },
  } = props;

  const value = Object.keys(formData || {}).length > 0 ? formData : undefined;
  const text = fullName(value);
  const defaultState = {
    isExpanded: false,
    results: value ? [value] : [],
    page: 1,
    countryState: '',
    query: '',
    loading: false,
    hasMoreResults: false,
    selected: value,
    moreResultsLoading: false,
  };

  const [state, dispatch] = useReducer(reducer, defaultState);

  const fetch = async (search, stateCode, page = 1, limit = PAGE_SIZE) => {
    try {
      const { data } = await client.query({
        query: SearchPhysicians,
        variables: {
          input: store.getState().guest.guestCheckinData.isGuest ? {
            search,
            limit,
            page,
            stateCode,
            is_guest_checkin: store.getState().guest.guestCheckinData.isGuest,
            username: store.getState().guest.guestCheckinData.username,
          } : {
            search,
            limit,
            page,
            stateCode,
          },
        },
      });

      return Array.isArray(data?.searchPhysicians) ? data?.searchPhysicians : [];
    } catch {
      return [];
    }
  };

  const handleSearch = async ({ query, countryState }) => {
    if (query === state.query && countryState === state.countryState) {
      return;
    }

    if (!query) {
      dispatch({ results: [], selected: undefined });
      return;
    }

    dispatch({
      loading: true,
      results: [],
      selected: undefined,
    });

    const finalQuery = query !== state.query ? query : state.query;
    const finalCountrySate = countryState !== state.countryState ? countryState : state.countryState;

    const newResults = await fetch(finalQuery, finalCountrySate);

    dispatch({
      loading: false,
      results: newResults,
      page: 1,
      query,
      countryState,
      hasMoreResults: newResults.length === PAGE_SIZE,
      selected: undefined,
    });
  };

  const handleLoadMore = async () => {
    dispatch({ moreResultsLoading: true });

    const newResults = await fetch(state.query, state.countryState, state.page + 1);

    dispatch({
      results: [...state.results, ...newResults],
      moreResultsLoading: false,
      page: state.page + 1,
      hasMoreResults: newResults.length === PAGE_SIZE,
    });
  };

  const handleApply = () => {
    onChange(state.selected);
    dispatch({ isExpanded: false });
  };

  return (
    <>
      {!state.isExpanded && (
        <FormField text={title} required={required} value={value}>
          <PrimaryContent>
            <div>
              <Text tag="p" text={text} type="primary" />
            </div>
            <div>
              <Button low main text={chooseText} secondary onClick={_ => dispatch({ isExpanded: !state.isExpanded })} />
            </div>
          </PrimaryContent>
        </FormField>
      )}
      {state.isExpanded && (
        <SecondaryContent>
          <SearchBoxField
            applyText={applyText}
            cancelText={cancelText}
            queryPlaceholderText={queryPlaceholderText}
            statePlaceholderText={statePlaceholderText}
            titleText={title}
            placeholderText={placeholderText}
            states={states}
            onCancel={_ => dispatch({ isExpanded: false })}
            onSearch={handleSearch}
            onApply={handleApply}
            selected={state.selected}
            loading={state.loading}
            results={state.results}
            query={state.query}
          >
            {state.results && (
              <>
                {(state.results || []).map(item => (
                  <PhysicianCard
                    key={item.npi}
                    item={item}
                    labels={detailsText}
                    isSelected={state.selected && state.selected.npi === item.npi}
                    onSelect={selected => dispatch({ selected })}
                  />
                ))}
                <Actions>
                  {state.moreResultsLoading ? (
                    <PageLoader className="centered" overlay={false} fixed={false} size={20} thickness={3} />
                  ) : (
                    <Button
                      disabled={!state.hasMoreResults}
                      primary
                      long
                      low
                      main
                      text={state.hasMoreResults ? viewMoreText : endOfResultsText}
                      onClick={handleLoadMore}
                    />
                  )}
                </Actions>
              </>
            )}
          </SearchBoxField>
        </SecondaryContent>
      )}
    </>
  );
});

export default withApollo(Component);
