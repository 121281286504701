import React, { memo } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ClientSideModal } from '../clientSideModal';
import { store } from '../../../models';
import { guestErrorContext as GuestErrorContext } from '../../../contexts';

const guestErrorModalContainer = memo(props => {
  const { clientSideModal: { transform, modalOpen, message } } = props;

  const closeModal = _ => {
    window.sessionStorage.clear();
    store.dispatch.clientSideModal.toggleClose();
  };

  return (
    <GuestErrorContext.Provider value={{ closeModal }}>
      <ClientSideModal
        {...{
          ...props,
          id: 'guestErrorModal',
          transform,
          modalOpen,
          message,
          iconId: 'apolloErrorIcon',
          iconIcon: 'exclamation-triangle',
          buttons: [
            {
              id: 'guestErrorBtn',
              theme: 'apolloErrorBtn',
              text: 'Ok',
              type: 'button',
              action: { trigger: 'closeModal', babyContext: 'guestError' },
              disabled: false,
              
            },
          ],
        }}
      />
    </GuestErrorContext.Provider>
  );
});

const mapStateToProps = ({ clientSideModal }) => ({ clientSideModal });

export default connect(mapStateToProps)(withRouter(guestErrorModalContainer));
