import React, { Component } from 'react';
import { isEmpty } from '@carecloud/cloudpak';
import { SchemaParser } from '../../services';
import styles from './jsonSchemaForm.module.scss';
import JsonSchemaForm from './jsonSchemaForm';
import { store } from '../../../models';

class JsonSchemaFormContainer extends Component {
  state = { page: 0, isSmallScreen: false };

  submitForm = _ => {
    this.form.submitButton.click();
    const missingFields = store.getState().appointmentCheckIn?.formValid
    if(missingFields === true){
      window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" })
    }
  };

  nextPage = _ => {
    this.setState(prevState => ({ page: prevState.page + 1 }));
  };

  resetForm = _ => this.setState({ page: 0 });

  checkScreenSize = _ => {
    this.setState({
      isSmallScreen: window.innerWidth <= 567,
    });
  }

  componentDidMount = _ => {
    this.checkScreenSize()
  }

  render() {
    const {
      props: { heading, leftSide, submitButton, title, description, totalPages },
    } = this.props;
    const { page, isSmallScreen } = this.state;
    window.scrollTo(0, 0);
    return (
      <div className={styles.wrapper}>
        <SchemaParser schema={heading} />
        <div className={styles.contentWrapper}>
          <div className={styles.leftSideWrapper}>
            <SchemaParser schema={leftSide} />
            {!isEmpty(submitButton) && !isSmallScreen && (
              <button id={submitButton.id} className={styles.submitButton} onClick={this.submitForm}>
                {this.state.page + 1 === totalPages ? submitButton.submitText : submitButton.nextFormText}
              </button>
            )}
          </div>
          <div className={styles.formWrapper}>
            <div className={styles.title}>{title[page]}</div>
            <div className={styles.description}>{description[page]}</div>
            <JsonSchemaForm
              resetForm={this.resetForm}
              nextPage={this.nextPage}
              styles={styles}
              pageNumber={this.state.page}
              props={this.props.props}
              ref={form => {
                this.form = form;
              }}
            />
            {!isEmpty(submitButton) && isSmallScreen && (
              <div className={styles.submitButtonDiv}>
                <button id={submitButton.id} className={styles.submitButton} onClick={this.submitForm}>
                  {this.state.page + 1 === totalPages ? submitButton.submitText : submitButton.nextFormText}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default JsonSchemaFormContainer;
