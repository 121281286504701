/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import { withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import React, { PureComponent } from 'react';
import { compose, get, isEmpty, isString } from '@carecloud/cloudpak';
import creditCardType from 'credit-card-type';
import moment from 'moment';
import * as CryptoJS from 'crypto-js';
import { store } from '../../../models';
import {
  AddToPaymentPlan,
  CancelAppointment,
  CreatePaymentPlan,
  EditPaymentPlan,
  MakeCancellationPayment,
  MakeCheckInPayment,
  MakeCheckoutPayment,
  MakeCheckoutPrepayment,
  MakeOneTimePayment,
  MakePayment,
  MakePrepayment,
  MakeShopPayment,
} from '../../../graphql/mutations';
import { GetGuestPatientStatement } from '../../../graphql/queries';
import { Paths, Payments } from '../../../constants/index';
import { PaymentAmount } from './paymentAmount';
import { parseParams } from '../../services/routing';
import styles from './paymentAmount.module.scss';
import { breakBackButton } from '../../services/utilities';
import { ContextProvider } from '../../root/TopLevelContext';

class PaymentAmountContainer extends PureComponent {
  static contextType = ContextProvider;
  componentDidMount() {
    const {
      amount: paymentAmount,
      addAppointment: { selectedProviderId: provider_id, locationId: location_ids },
    } = this.props;
    const match = JSON.parse(this.props.match);
    const {
      params: { practiceId },
    } = match;
    store.dispatch.mixpanel.addNonActionableMetadata({
      paymentAmount,
      practiceId,
      location_id: location_ids,
      provider_id,
    });
    this.context._setActions({
      handleSelectCash: this.handleSelectCash,
      handleSelectCreditCard: this.handleSelectCreditCard,
    });
    breakBackButton();
  }
  componentWillUnmount() {
    store.dispatch.radioInput.resetRadioInput();
    store.dispatch.payments.clearPayments();
    store.dispatch.makePayments.clearMakePayments();
    store.dispatch.partialPayment.resetInput();
  }

  loadPatientStatement = async () => {
    const param = store.getState().guest.guestPaymentData.payload.patient_statements[0].statements[0].id;
    // store.dispatch.payments.clearPayments();
    store.dispatch.loader.activateLoader({ loading: true });
    const { data: { getGuestPatientStatement: { base64Str = String() } = {} } } = await this.props.client.query({
      query: GetGuestPatientStatement,
      variables: { 
        statementId: param.toString(),
        practiceId: store.getState().guest.guestPaymentData.payload.patient_info.practice_id,
        patientId: store.getState().guest.guestPaymentData.payload.patient_info.patient_id,
        practiceMgmt: store.getState().guest.guestPaymentData.payload.patient_info.practice_mgmt,
        // userId: '1231231asdasdas12312',
      },
    });
    const byteCharacters = atob(base64Str);
    const byteNumbers = new Array(byteCharacters.length);
    [...byteCharacters].forEach((value, i) => {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    });
    const byteArray = new Uint8Array(byteNumbers);
    const file = new Blob([byteArray], { type: 'application/pdf' });
    if (navigator && navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(file);
    } else {
      const url = URL.createObjectURL(file);
      window.open(url);
    }
    return store.dispatch.loader.activateLoader({ loading: false });
  };

  clickHandler = id => store.dispatch.modal.toggleOpen(id);
  sanitizeAmount = amount => (isString(amount) ? parseFloat(amount.replace(/,|[^\d.]/g, '')) : amount); //eslint-disable-line no-confusing-arrow

  handlePay = async e => {
    e.preventDefault();
    const {
      client,
      shopMakePayment,
      paymentPlan,
      creditCard: { processing, saveCard, validation, name: cardholder_name, expiration, number: card_number },
      formGroup: {
        fields: { makePaymentsOneTimeAmount },
        events: formGroupEvents,
      },
      location: { pathname },
      loader: { loading },
      amount,
      copay,
      executionValue,
      previousBalance,
      description,
      partialPayment,
      radioInput: {
        selectedExistingCard,
        paymentType,
        newCardForm,
        cancellationReasonValue: cancellationReasonId,
        cancellationReason,
      },
      appointments: { appointmentData },
      addAppointment,
      appointmentCheckOut,
      guestEntry,
      merchant,
    } = this.props;
    const match = JSON.parse(this.props.match);
    const {
      url,
      params: { patientId, practiceId, paymentPlanId },
    } = match;
    const { creditCard, loader, mixpanel, modal } = store.dispatch;
    let cardId = null;
    if (selectedExistingCard) cardId = selectedExistingCard;

    const getCardType = cardNumber => {
      const cards = creditCardType(cardNumber && cardNumber.replace(/\s/g, ''));
      const { type } = cards[0];
      return type === 'master-card' ? type.replace(/-/g, '') : type.replace(/-/g, ' ');
    };

    const cancelAppointment = async () => {
      const { cancellationReason, cancellationReasonValue: cancellationReasonId } = store.getState().radioInput;
      const foundCancellationComments = store
        .getState()
        .formGroup.events.find(event => event.id === 'otherReasonInput' && !event.isInitial);
      const cancellationComments =
        cancellationReason === 'OT' ? foundCancellationComments && foundCancellationComments.value : String();
      store.dispatch.modal.toggleClose();
      store.dispatch.loader.activateLoader({ loading: true });
      
      const { PrePaymentRescheduleInput } = store.getState().addAppointment
      const customInput = {
        practiceId: PrePaymentRescheduleInput.practiceId,
        patientId: PrePaymentRescheduleInput.patientId,
        appointmentId: PrePaymentRescheduleInput.appointmentId,
        providerId: PrePaymentRescheduleInput.providerId,
        locationId: PrePaymentRescheduleInput.locationId,
        cancellationReasonId: cancellationReasonId || '1', 
        cancellationComments,
      }
      
      try {
        store.dispatch.appointments.toggleCancellingAppointment();
        store.dispatch.mixpanel.addNonActionableMetadata({ appointmentCancellationVisitType: PrePaymentRescheduleInput.visitType });
        await this.props.client.mutate({ mutation: CancelAppointment, variables: { input: customInput } });
        // if (data?.data?.cancelAppointment === 200) {
        //   // store.dispatch.modal.toggleOpen('cancelApptSuccessModal');
        //   store.dispatch.loader.activateLoader({ loading: false });
        //   // fetchDashboard();
        // }
        store.dispatch.radioInput.resetRadioInput();
      } catch (error) {
        console.error(error);
      }
  };

    const addCardData = _ =>
      cardId
        ? { cardId }
        : JSON.stringify({
            type: getCardType(card_number).replace(/\b\w/g, l => l.toUpperCase()),
            saveCard,
            cardholder_name,
            exp_date: expiration && expiration.replace(/\D/g, ''),
            cvv: validation,
            card_number: card_number.replace(/\s/g, ''),
            merchant,
          });
    let paymentAmount = this.sanitizeAmount(amount);
    if (paymentType === Payments.PARTIAL_PAYMENT) paymentAmount = this.sanitizeAmount(partialPayment.input);
    if (pathname.endsWith(Paths.SCHEDULE_PAYMENT)) paymentAmount = this.sanitizeAmount(makePaymentsOneTimeAmount);
    if (pathname.endsWith(Paths.PREPAYMENT)) paymentAmount = this.sanitizeAmount(addAppointment.prePayment);
    if (!processing) {
      creditCard.toggleProcessing(true);
      loader.activateLoader({ loading: true });
    }

    let response = null;
    if(guestEntry.guestMode) {
      const { guestMode } = guestEntry;
      const res = await client.mutate({
        mutation: MakePayment,
        variables: {
          input: {
            username: Object.keys(store.getState().guest.guestPaymentData).length === 0 ? store.getState().guest.guestCheckinData.username : store.getState().guest.guestPaymentData.payload?.patient_balances?.[0]?.demographics?.metadata?.username,
            userId: Object.keys(store.getState().guest.guestPaymentData).length === 0 ? store.getState().guest.guestCheckinData.user_id : store.getState().guest.guestPaymentData.payload?.patient_balances?.[0]?.demographics?.metadata?.user_id,
            //eslint-disable-next-line no-nested-ternary
            patientId: guestMode ? 
              parseParams(this.props).patientId ? 
                parseParams(this.props).patientId : 
                parseParams(this.props).patient_id : 
              patientId,
              //eslint-disable-next-line no-nested-ternary
            practiceId: guestMode ? 
              parseParams(this.props).practiceId ? 
                parseParams(this.props).practiceId : 
                parseParams(this.props).practice_id : 
              practiceId,
            paymentPlanId,
            //eslint-disable-next-line no-nested-ternary
            amount: paymentAmount,
            execution: sessionStorage.getItem("execution") === 'no-value' ? 
            executionValue : 
            sessionStorage.getItem("execution"),
            //eslint-disable-next-line no-nested-ternary
            practiceMgmt: guestMode ? 
              sessionStorage.getItem("practiceManagement") ? 
                sessionStorage.getItem("practiceManagement") : 
                parseParams(this.props).practice_mgmt : 
              '',
            description,
            ...(cardId ? { cardId } : { plainTextCardData: addCardData() }),
            guestMode,
            ...(guestMode
              ? {
                  guestPayBalance: get(JSON.parse(sessionStorage.getItem('CC-Breeze-Web-persistedGuestData')),
                    'payload.patient_balances[0].pending_balances[0].payload[0].amount',
                    String()) === '' ? 
                  amount :
                  get(JSON.parse(sessionStorage.getItem('CC-Breeze-Web-persistedGuestData')),
                    'payload.patient_balances[0].pending_balances[0].payload[0].amount',
                    String(),
                  ),
                }
              : {}),
          },
        },
      });
      response = get(res, 'data.makePayment');
    } else if (pathname.endsWith(Paths.CANCELLATION)) {
      const { practice_id, patient_id, location_id, provider_id, appointment_id } = appointmentData;
      const foundCancellationComments = formGroupEvents.find(
        event => event.id === 'otherReasonInput' && !event.isInitial,
      );
      const cancellationComments =
        cancellationReason === 'OT' ? foundCancellationComments && foundCancellationComments.value : String();
      const { data } = await client.mutate({
        mutation: MakeCancellationPayment,
        variables: {
          input: {
            practice_id,
            patient_id,
            location_id,
            provider_id,
            appointment_id,
            execution: sessionStorage.getItem("execution"),
            amount: paymentAmount,
            ...(cardId ? { cardId } : { plainTextCardData: addCardData() }),
            cancellationComments,
            cancellationReasonId,
          },
        },
      });
      response = data.makeCancellationPayment;
    } else if (pathname.endsWith(Paths.SCHEDULE_PAYMENT) || pathname.includes(Paths.EDIT_ONE_TIME)) {
      const {
        oneTimePayment: { patientId },
        datePicker: { oneTimePaymentDatePicker: date },
      } = this.props;
      const match = JSON.parse(this.props.match);
      const {
        params: { practiceId, paymentPlanId, oneTimePaymentId },
      } = match;
      let res;

      try {
        res = await client.mutate({
          mutation: MakeOneTimePayment,
          variables: {
            input: {
              practiceId,
              paymentPlanId,
              amount:
                this.sanitizeAmount(makePaymentsOneTimeAmount) ||
                this.sanitizeAmount(
                  formGroupEvents
                    .find(event => event.id === 'makePaymentsOneTimeAmount')
                    .value.toString()
                    .replace(/[^\d.]/g, ''),
                ),
              patientId,
              execution: sessionStorage.getItem("execution"),
              date,
              ...(cardId ? { cardId } : { plainTextCardData: addCardData() }),
              ...(pathname.includes(Paths.EDIT_ONE_TIME)
                ? { state: 'editOneTime', oneTimePaymentId }
                : { state: 'makeOneTime' }),
            },
          },
        });
      } catch (e) {
        window.console.log(e, res);
      }
      response = get(res, 'data.makeOneTimePayment', 200);
      response === 200 && mixpanel.addMetadata({ scheduledPayment: true });
    } else if (paymentType === Payments.PAYMENT_PLAN) {
      const { option, planName, monthlyPayment, numberOfMonths, newLineItems } = paymentPlan;
      const { dayOfMonth, dayOfWeek, paymentFrequency, paymentFrequencyDisabled } = this.props.inputDropdown;
      const isCheckOut = pathname.endsWith(Paths.CHECK_OUT);
      const isCheckIn = pathname.endsWith(Paths.CHECK_IN);
      const { appointment_id, patient_id, practice_id, findings } = isCheckOut
        ? this.props.appointmentCheckOut
        : this.props.appointmentCheckIn;
      if (option === Payments.EXISTING) {
        const { id, monthlyPayment, currentPlan, lineItems, planName, cardId: paymentPlanCardId } = paymentPlan;
        const totalAmount =
          currentPlan.originalAmount +
          parseFloat(partialPayment.displayInput.replace(/[^\d.]/g, '') || previousBalance || amount);
        const partialPaymentAmount = parseFloat(partialPayment.displayInput.replace(/[^\d.]/g, ''));
        const { data } = await client.mutate({
          mutation: AddToPaymentPlan,
          variables: {
            input: {
              description: planName,
              totalAmount,
              cardId: paymentPlanCardId,
              paymentAmount: this.sanitizeAmount(monthlyPayment),
              partialPaymentAmount,
              dayOfMonth,
              dayOfWeek,
              findings,
              execution: sessionStorage.getItem("execution"),
              pendingAmount: this.sanitizeAmount(previousBalance || amount),
              frequencyCode: paymentFrequency || paymentFrequencyDisabled,
              installments: +numberOfMonths + +currentPlan.installmentPaid,
              patientId: patientId || patient_id,
              practiceId: practiceId || practice_id,
              paymentPlanId: id,
              lineItems,
              appointmentId: appointment_id,
              type: isCheckOut ? Payments.CHECK_OUT : Payments.DEFAULT,
            },
          },
        });
        if (isCheckIn || isCheckOut) store.dispatch.appointmentCheckIn.paymentPlanCreated(true);
        if (isCheckOut) store.dispatch.appointmentCheckOut.paymentPlanCreated(true);
        response = data.addToPaymentPlan;
      } else if (option === Payments.NEW) {
        const totalAmount = this.sanitizeAmount(partialPayment.displayInput.replace(/[^\d.]/g, ''));
        const { data } = await client.mutate({
          mutation: CreatePaymentPlan,
          variables: {
            input: {
              planName,
              totalAmount,
              paymentAmount: this.sanitizeAmount(monthlyPayment),
              dayOfMonth,
              dayOfWeek,
              execution: sessionStorage.getItem("execution"),
              frequencyCode: paymentFrequency || paymentFrequencyDisabled,
              installments: +numberOfMonths,
              patientId: patientId || patient_id,
              practiceId: practiceId || practice_id,
              lineItems: newLineItems,
              appointmentId: appointment_id,
              ...(cardId ? { cardId } : { plainTextCardData: addCardData() }),
              type: isCheckOut ? Payments.CHECK_OUT : Payments.DEFAULT,
            },
          },
        });
        mixpanel.addMetadata({
          paymentPlanSubmittedOrEdited: {
            practiceId: practiceId || practice_id,
            paymentPlanId: get(data, 'createPaymentPlan.payment_plan_id'),
            totalAmount,
            paymentAmount: monthlyPayment,
            ...((paymentFrequency || paymentFrequencyDisabled) === Payments.WEEKLY
              ? { paymentDay: dayOfWeek }
              : { paymentDay: dayOfMonth }),
            installments: +numberOfMonths,
            frequencyCode: paymentFrequency || paymentFrequencyDisabled,
            isNew: true,
          },
        });
        isCheckOut &&
          mixpanel.addNonActionableMetadata({
            checkoutPaymentPlanCreated: true,
          });
        if (isCheckIn) store.dispatch.appointmentCheckIn.paymentPlanCreated(true);
        if (isCheckOut) store.dispatch.appointmentCheckOut.paymentPlanCreated(true);

        response = data?.createPaymentPlan?.status;
      } else if (option === Payments.EDIT) {
        const { lineItems, planName, patientId, existingPlanName, originalAmount, paymentsMade } = paymentPlan;
        const { dayOfMonth, dayOfWeek } = this.props.inputDropdown;
        const { data } = await client.mutate({
          mutation: EditPaymentPlan,
          variables: {
            input: {
              planName: planName || isString(planName) ? planName : existingPlanName,
              totalAmount: parseFloat(originalAmount),
              monthlyPayment: parseFloat(monthlyPayment),
              dayOfMonth,
              dayOfWeek,
              installments: +numberOfMonths + +paymentsMade,
              patientId,
              practiceId,
              paymentPlanId,
              execution: sessionStorage.getItem("execution"),
              lineItems,
              frequencyCode: paymentFrequency || paymentFrequencyDisabled,
              ...(cardId ? { cardId } : { plainTextCardData: addCardData() }),
            },
          },
        });
        mixpanel.addMetadata({
          paymentPlanSubmittedOrEdited: {
            practiceId,
            paymentPlanId: get(data, 'createPaymentPlan.payment_plan_id'),
            totalAmount: originalAmount,
            paymentAmount: +monthlyPayment,
            ...((paymentFrequency || paymentFrequencyDisabled) === Payments.WEEKLY
              ? { paymentDay: dayOfWeek }
              : { paymentDay: dayOfMonth }),
            installments: +numberOfMonths + +paymentsMade,
            frequencyCode: paymentFrequency || paymentFrequencyDisabled,
            isNew: false,
          },
        });
        response = get(data, 'editPaymentPlan.status');
      }
    } else if (pathname.endsWith(Paths.PREPAYMENT)) {
      if(store.getState().addAppointment.PrePaymentRescheduleInput.isCancelAppointment){
        cancelAppointment();
        const {
          locationGuid,
          selectedProvider: { patientId: patient_id, practiceId: practice_id, practiceMgmt: practice_mgmt, providerGuid },
          selectedProviderId: provider_id,
          visitReason,
        } = addAppointment;
        const { appointment_id } = appointmentCheckOut;
        const {
          availableHours: { appointment },
          dateTimeSelect: {
            availableHoursSection: {
              value: { start_time, end_time },
            },
          },
        } = this.props;
        if (this.props.appointmentCheckOut.appointmentCheckOut.length > 0) {
          const appointmentInput = {
            ...appointment,
            provider_id,
            start_time,
            end_time,
            ...(visitReason ? { chief_complaint: visitReason } : {}),
          };
          const prepayment = {
            amount: paymentAmount,
            appointment: appointmentInput,
            locationGuid,
            providerGuid,
            ...(cardId ? { cardId } : { plainTextCardData: addCardData() }),
          };
          const checkoutIds = {
            patient_id,
            practice_id,
            practice_mgmt,
            appointment_id,
          };
          const { data } = await client.mutate({
            mutation: MakeCheckoutPrepayment,
            variables: {
              input: {
                prepayment,
                checkoutIds,
                execution: sessionStorage.getItem("execution"),
              },
            },
          });
          response = data.makeCheckoutPrepayment;
        } else {
          const appointmentInput = {
            ...appointment,
            provider_id: typeof provider_id === 'string' ? Number(provider_id) : provider_id,
            start_time,
            end_time,
            ...(visitReason ? { chief_complaint: visitReason } : {}),
          };
          const prepayment = {
            amount: paymentAmount,
            appointment: appointmentInput,
            locationGuid,
            providerGuid,
            ...(cardId ? { cardId } : { plainTextCardData: addCardData() }),
          };
          const patientInformation = {
            patientId: patient_id,
            practiceId: practice_id,
            practiceMgmt: practice_mgmt,
          };
          const { data } = await client.mutate({
            mutation: MakePrepayment,
            variables: {
              input: {
                prepayment,
                patientInformation,
                execution: sessionStorage.getItem("execution"),
              },
            },
          });
          response = data.makePrepayment;
        }
      }
      else{
        const {
          locationGuid,
          selectedProvider: { patientId: patient_id, practiceId: practice_id, practiceMgmt: practice_mgmt, providerGuid },
          selectedProviderId: provider_id,
          visitReason,
        } = addAppointment;
        const { appointment_id } = appointmentCheckOut;
        const {
          availableHours: { appointment },
          dateTimeSelect: {
            availableHoursSection: {
              value: { start_time, end_time },
            },
          },
        } = this.props;
        if (this.props.appointmentCheckOut.appointmentCheckOut.length > 0) {
          const appointmentInput = {
            ...appointment,
            provider_id,
            start_time,
            end_time,
            ...(visitReason ? { chief_complaint: visitReason } : {}),
          };
          const prepayment = {
            amount: paymentAmount,
            appointment: appointmentInput,
            locationGuid,
            providerGuid,
            ...(cardId ? { cardId } : { plainTextCardData: addCardData() }),
          };
          const checkoutIds = {
            patient_id,
            practice_id,
            practice_mgmt,
            appointment_id,
          };
          const { data } = await client.mutate({
            mutation: MakeCheckoutPrepayment,
            variables: {
              input: {
                prepayment,
                checkoutIds,
                execution: sessionStorage.getItem("execution"),
              },
            },
          });
          response = data.makeCheckoutPrepayment;
        } else {
          const appointmentInput = {
            ...appointment,
            provider_id: typeof provider_id === 'string' ? Number(provider_id) : provider_id,
            start_time,
            end_time,
            ...(visitReason ? { chief_complaint: visitReason } : {}),
          };
          const prepayment = {
            amount: paymentAmount,
            appointment: appointmentInput,
            locationGuid,
            providerGuid,
            ...(cardId ? { cardId } : { plainTextCardData: addCardData() }),
          };
          const patientInformation = {
            patientId: patient_id,
            practiceId: practice_id,
            practiceMgmt: practice_mgmt,
          };
          const { data } = await client.mutate({
            mutation: MakePrepayment,
            variables: {
              input: {
                prepayment,
                patientInformation,
                execution: sessionStorage.getItem("execution"),
              },
            },
          });
          response = data.makePrepayment;
        }
      }
    } else if (pathname.endsWith(Paths.CHECK_OUT)) {
      const { appointment_id, patient_id, practice_id, location_guid, provider_guid } = this.props.appointmentCheckOut;
      const { data } = await client.mutate({
        mutation: MakeCheckoutPayment,
        variables: {
          input: {
            practiceId: practice_id,
            patientId: patient_id,
            locationId: location_guid,
            providerId: provider_guid,
            appointmentId: appointment_id,
            execution: sessionStorage.getItem("execution"),
            amount: paymentAmount,
            patientBalance: parseFloat(previousBalance || amount),
            copay,
            ...(cardId ? { cardId } : { plainTextCardData: addCardData() }),
          },
        },
      });

      mixpanel.addMetadata({ checkoutPaymentDone: true });
      mixpanel.addNonActionableMetadata({
        checkoutPaymentAmount: paymentAmount,
      });
      response = data.makeCheckoutPayment;
    } else if (pathname.endsWith(Paths.CHECK_IN) && !guestEntry.guestMode) {
      const {
        guestEntry : {
          guestMode,
        },
        appointmentCheckIn: {
          provider_id,
          location_id,
          appointment_id,
          patient_id,
          practice_id,
          queryString: { findings_id },
        },
      } = this.props;
      const { data } = await client.mutate({
        mutation: MakeCheckInPayment,
        variables: {
          input: {
            patientId: guestMode ? parseParams(this.props).patient_id : patient_id,
            practiceId: guestMode ? parseParams(this.props).practice_id : practice_id,
            amount: paymentAmount,
            patientBalance: parseFloat(previousBalance || amount),
            copay,
            execution: sessionStorage.getItem("execution"),
            providerId: provider_id,
            locationId: location_id,
            appointmentId: guestMode ? parseParams(this.props).appointment_id : appointment_id,
            findingsId: findings_id,
            ...(cardId ? { cardId } : { plainTextCardData: addCardData() }),
          },
        },
      });
      response = data.makeCheckInPayment;
    } else if (!isEmpty(shopMakePayment.shopMakePayment)) {
      const {
        paymentData: { patientId, practiceId },
      } = shopMakePayment;
      const { store_id, transaction_id, amount } = parseParams(this.props);
      const { data } = await client.mutate({
        mutation: MakeShopPayment,
        variables: {
          input: {
            patientId,
            practiceId,
            storeId: store_id,
            transactionId: transaction_id,
            execution: sessionStorage.getItem("execution"),
            amount: paymentAmount,
            ...(cardId ? { cardId } : { plainTextCardData: addCardData() }),
          },
        },
      });
      const { status, redirect } = data.makeShopPayment;
      response = status;
      store.dispatch.shopMakePayment.storeSuccessPath(redirect);
    } else {
      const res = await client.mutate({
        mutation: MakePayment,
        variables: {
          input: {
            patientId,
            practiceId,
            paymentPlanId,
            amount: paymentAmount,
            execution: sessionStorage.getItem("execution"),
            practiceMgmt: '',
            description,
            ...(cardId ? { cardId } : { plainTextCardData: addCardData() }),
          },
        },
      });
      response = get(res, 'data.makePayment');

    }

    const hasErrors = store.getState().error.errors;
    if (!loading) creditCard.toggleProcessing(false);
    if (guestEntry.guestMode && response?.status === 200 && !hasErrors) return this.transitionGuestSuccess(response);
    if ((response === 200 && !hasErrors) || response?.submissionResult?.success) {
      if (
        (url.includes(Paths.CHECK_IN) || url.includes(Paths.CHECK_OUT)) &&
        paymentType === Payments.PAYMENT_PLAN &&
        copay > 0
      ) {
        modal.toggleOpen('makePaymentsSuccessNext');
      } else {
        modal.toggleOpen('makePaymentsSuccess');
      }
    }
    if (response?.appointment_id) {
      store.dispatch.appointmentCheckIn.storeAppointmentData(response);
      modal.toggleOpen('makePaymentsSuccess');
    }
    return store.dispatch.error.resetError();
  };

  transitionGuestSuccess = paymentInfo => {
    const {
      history: { push },
    } = this.props;
    store.dispatch.guest.clearGuestSuccess();
    store.dispatch.guest.setGuestSuccessData({
      ...paymentInfo,
      workflow: 'payments',
    });
    push('/guest/workflow/payments/payment_success');
  };

  handleSelectCash = param => {
    store.dispatch.modal.toggleOpen(param);
    store.dispatch.loader.persistLoader({ persist: false });
    store.dispatch.payments.togglePaymentButton(true);
  };
  handleSelectCreditCard = () => {
    store.dispatch.payments.togglePaymentButton(false);
    store.dispatch.loader.persistLoader({ persist: false });
  };

  _getPaymentText = text => {
    const { location: { pathname } = {}, radioInput: { paymentType } = {} } = this.props;
    if (pathname.endsWith(Paths.SCHEDULE_PAYMENT)) {
      return text.oneTimePayment;
    } else if (paymentType === Payments.PARTIAL_PAYMENT) {
      return text.payPartial;
    } else if (paymentType === Payments.PAYMENT_PLAN) {
      return text.paymentPlan;
    } else if (pathname.includes(Paths.EDIT_ONE_TIME)) {
      return text.editOneTimePayment;
    }
    return text.fullPayment;
  };
  render() {
    const { guestPaymentData: { payload: { patient_info = {}, patient_statements = [], portal_settings } = [] } = {} } = store.getState()?.guest;
    const { practice_mgmt } = patient_info;
    const  patientStatementBit  = portal_settings?.[0]?.payload?.find(data => data?.type_name === "Financials" && data?.name === "Account Statements").status === 'A';
    const {
      text,
      amount,
      formGroup: {
        fields: { makePaymentsOneTimeAmount },
        events,
      },
      location: { pathname },
      radioInput: { paymentType, paymentMethod, selectedExistingCard },
      creditCard: { creditCardValid },
      partialPayment: { input, error },
      paymentPlan: { numberOfMonths, monthlyPayment, option, agreeToTerms, termsSent },
      makePayments: { isValid },
      inputDropdown: { dayOfMonth, dayOfWeek, paymentFrequency, paymentFrequencyDisabled, weeklyOptions },
      shopMakePayment: { shopMakePayment },
      datePicker: { oneTimePaymentDatePicker: date },
      addAppointment: { selectedProviderId: provider_id, locationId: location_ids },
      payments: { paymentBtnDisabled },
    } = this.props;
    const match = JSON.parse(this.props.match);
    const {
      params: { practiceId },
    } = match;
    if (
      amount &&
      !pathname.endsWith(Paths.PREPAYMENT) &&
      !store.getState().mixpanel.nonActionableMetadata.paymentAmount
    ) {
      store.dispatch.mixpanel.addNonActionableMetadata({
        paymentAmount: amount,
        practiceId,
        location_id: location_ids,
        provider_id,
      });
    }

    let amountDisplay = false;
    if (input) {
      if (input !== '-') amountDisplay = input.replace(/[^\d.]/g, '');
    } else if (pathname.endsWith(Paths.SCHEDULE_PAYMENT)) {
      if (makePaymentsOneTimeAmount) amountDisplay = makePaymentsOneTimeAmount.replace(/[^\d.]/g, '');
    } else if (paymentType === Payments.PAYMENT_PLAN) {
      amountDisplay = monthlyPayment;
    } else if (pathname.includes(Paths.EDIT_ONE_TIME)) {
      if (makePaymentsOneTimeAmount) {
        amountDisplay = makePaymentsOneTimeAmount.replace(/[^\d.]/g, '');
      } else {
        amountDisplay =
          events.find(e => e.id === 'makePaymentsOneTimeAmount') &&
          events.find(e => e.id === 'makePaymentsOneTimeAmount').value.replace(/[^\d.]/g, '');
      }
    } else {
      amountDisplay = amount;
    }
    let dayDisplay = null;
    let monthDisplay = null;
    const frequency = paymentFrequency || paymentFrequencyDisabled;
    if (paymentType === Payments.PAYMENT_PLAN) {
      if (frequency === Payments.WEEKLY && weeklyOptions) {
        weeklyOptions.forEach(item => {
          if (item.value === dayOfWeek) dayDisplay = item.label;
        });
      } else {
        dayDisplay = dayOfMonth;
      }
      monthDisplay = numberOfMonths;
    }

    let payBtnInvalid;
    if (
      (paymentType === Payments.PAYMENT_PLAN && option === Payments.NEW) ||
      pathname.endsWith(Paths.SCHEDULE_PAYMENT) ||
      pathname.endsWith(Paths.EDIT_PAYMENT_PLAN)
    ) {
      payBtnInvalid = !isValid || !creditCardValid || (termsSent && !agreeToTerms) || error.display;
    } else if (paymentType === Payments.PARTIAL_PAYMENT) {
      payBtnInvalid = !isValid || !creditCardValid;
    } else if (
      paymentType === Payments.FULL_PAYMENT ||
      pathname.endsWith(Paths.PREPAYMENT) ||
      (pathname.endsWith(Paths.CHECK_OUT) && option !== Payments.EXISTING) ||
      pathname.endsWith(Paths.CANCELLATION) ||
      !isEmpty(shopMakePayment)
    ) {
      payBtnInvalid = !creditCardValid;
    } else if (paymentType === Payments.PAYMENT_PLAN && option === Payments.EXISTING) {
      payBtnInvalid = (termsSent && !agreeToTerms) || !isValid || error.display;
    } else if (pathname.includes(Paths.EDIT_ONE_TIME)) {
      payBtnInvalid = !isValid || error.display;
    }

    const paymentDateToday =
      pathname.includes(Paths.SCHEDULE_PAYMENT) &&
      moment(date)
        .startOf('day')
        .utc()
        .isSame(
          moment()
            .startOf('day')
            .utc(),
          'day',
        );
    return (
      <PaymentAmount
        {...{
          ...this.props,
          styles,
          input: input && input.replace(/[^\d.]/g, ''),
          paymentType,
          payment: this._getPaymentText(text),
          monthDisplay,
          dayDisplay,
          monthlyPayment,
          isValid,
          amountDisplay,
          paymentMethod,
          creditCardValid,
          selectedExistingCard,
          paymentFrequency: paymentFrequency || paymentFrequencyDisabled,
          payBtnInvalid,
          handlePay: this.handlePay,
          monthlyFrequencyChosen: frequency === Payments.MONTHLY,
          paymentDateToday,
          paymentBtnDisabled,
          loadPatientStatement: this.loadPatientStatement, 
          practice_mgmt,
          patient_statements,
          patientStatementBit,
        }}
      />
    );
  }
}

const mapStateToProps = ({
  radioInput,
  partialPayment,
  paymentPlan,
  makePayments,
  inputDropdown,
  creditCard,
  loader,
  formGroup,
  appointments,
  addAppointment,
  dateTimeSelect,
  availableHours,
  oneTimePayment,
  shopMakePayment,
  appointmentCheckOut,
  appointmentCheckIn,
  datePicker,
  guestEntry,
  payments,
}) => ({
  radioInput,
  partialPayment,
  paymentPlan,
  makePayments,
  inputDropdown,
  creditCard,
  loader,
  formGroup,
  appointments,
  addAppointment,
  dateTimeSelect,
  availableHours,
  oneTimePayment,
  shopMakePayment,
  appointmentCheckOut,
  appointmentCheckIn,
  datePicker,
  guestEntry,
  payments,
});

const enhancer = compose(withApollo, connect(mapStateToProps));
export default enhancer(PaymentAmountContainer);
