import React, { useEffect, useReducer } from 'react';
import styled from 'styled-components';
import { CheckBoxField, FormField, Help, ListControls, Text, TypeheadField } from '../../styleguide';
import { reducer, getIsCompleted, getListed, getValidState } from './Allergies.reducer';
import { searchAllergies as graphqlSearchAllergies } from '../../../graphql/queries';
import { store } from '../../../models';

const Details = styled.div`
  margin: 32px 0 0;
`;

export default React.memo(props => {
  const {
    formData = [],
    onChange,
    schema,
    schema: { title, description, showCheckmark },
    rawErrors,
  } = props;

  const {
    addText,
    clearText,
    checkInIds,
    removeText,
    title: typeheadTitle,
    placeholder: typeheadPlaceholder,
  } = schema.properties.allergiesArray.items;

  const [state, dispatch] = useReducer(reducer, {
    noAllergiesCheckbox: formData.noAllergiesCheckbox,
    allergiesArray: formData.allergiesArray,
  });

  const handleAdd = _ => dispatch({ type: 'addListed' });
  const handleRemove = interoperableID => dispatch({ type: 'removeListed', interoperableID });
  const handleClear = interoperableID => dispatch({ type: 'clearListed', interoperableID });
  const handleUpdate = (interoperableID, payload) => dispatch({ type: 'updateListed', interoperableID, payload });
  const handleTransformRequest = input => {
    if(store.getState().guest?.guestCheckinData?.isGuest) {
      return {...input, 
        checkInIds: { ...checkInIds, 
          username: store.getState().guest?.guestCheckinData?.username, 
          is_guest_checkin: store.getState().guest?.guestCheckinData?.isGuest,
        },
      }
    } else return { ...input, checkInIds }
  }

  useEffect(() => {
    onChange(getValidState(state));
  }, [state]);

  const showDetails = !state.noAllergiesCheckbox;
  const hasErrors = (rawErrors || []).length > 0;
  const isRequired = !getIsCompleted(state);
  const listedAllergies = getListed(state);

  if (showDetails && listedAllergies.length < 1) {
    dispatch({ type: 'addListed' });
  }
  const getConcileData =  getListed(state)?.filter(x => x?.allow_delete === false)
  const practiceMgmt = JSON.parse(sessionStorage.getItem('Patient-Ids-List'))?.[0]?.practice_mgmt;
  const handleCheckboxChange = ({ target: { checked } }) => {
    if(getConcileData?.length && practiceMgmt === 'talkehr'){
      store.dispatch.modal.toggleOpen('reconcileAllergiesModal');
      store.dispatch.modal.toggleClose('reconcileAllergiesModal');
    }
    else{
      dispatch({ type: 'setNoAllergiesCheckbox', noAllergiesCheckbox: checked })
    }
  };
  let listedItems = 0;
  return (
    <>
      <Text tag="p" text={title} type="groupTitle" icon={showCheckmark && !hasErrors} />
      <Help text={description}>
        <FormField>
          <CheckBoxField
            name="noAllergiesCheckbox"
            value={state.noAllergiesCheckbox}
            text={schema.properties.noAllergiesCheckbox.title}
            onChange={handleCheckboxChange}
          />
        </FormField>
        {showDetails && (
          <Details>
            {state.allergiesArray.map(allergy => {
              const displayOnUI = allergy.action !== 'delete';
              displayOnUI && listedItems++;
              return (
                displayOnUI && (
                  <>
                    <FormField text={typeheadTitle} required={isRequired} value={allergy?.interoperableID}>
                      <TypeheadField
                        name="allergy"
                        placeholder={typeheadPlaceholder}
                        labelKey="interoperableDesc"
                        valueKey="interoperableID"
                        disabled={allergy?.interoperableID}
                        onChange={({ target: { value } }) => handleUpdate(allergy?.interoperableID, value)}
                        value={allergy.action !== 'delete' ? allergy : undefined}
                        query={graphqlSearchAllergies}
                        transformRequest={input => handleTransformRequest(input)}
                        transformResponse={output => output?.searchAllergies || []}
                      />
                    </FormField>
                    <ListControls
                      addText={addText}
                      canAdd={!!allergy?.interoperableID}
                      canClear={!!allergy?.interoperableID}
                      canRemove={getListed(state).length > 1 && allergy.allow_delete === true || allergy.allow_delete === undefined}
                      clearText={clearText}
                      onAdd={handleAdd}
                      onClear={_ => handleClear(allergy?.interoperableID)}
                      onRemove={_ => handleRemove(allergy?.interoperableID)}
                      removeText={removeText}
                      showAdd={!allergy?.interoperableID || listedItems === getListed(state).length}
                      showClear={!!allergy?.interoperableID && getListed(state).length === 1}
                      showRemove={getListed(state).length > 1 || !allergy?.interoperableID}
                    />
                  </>
                )
              );
            })}
          </Details>
        )}
      </Help>
    </>
  );
});
