import { get, set } from '@carecloud/cloudpak';
import React, { PureComponent } from 'react';
import { SchemaParser } from '../../services';
import { intakeForms } from '../../../graphql/mutations';
import { store } from '../../../models';
import styles from './intakeForm.module.scss';
import { intakeFormContext as IntakeFormContext } from '../../../contexts';

class IntakeFormContainer extends PureComponent {
  state = { formIndex: 0, forms: [], enableNextStep: false };
  componentDidMount() {
    // window.addEventListener('resize', this.checkScreenSize());
    let usingIntakeForm = false;
    store.dispatch.loader.persistLoader({ persist: true });
    // this.checkScreenSize()
    this.ifr.onload = () => {
      this.ifr.contentWindow.IntakeInterface = {
        savedIntake: this.savedIntake,
        scroll_form: data => {
          usingIntakeForm = true;
          store.dispatch.appointmentCheckIn.TakingIntakeformBit(usingIntakeForm)
          const { pageScrollOffsetPercentage } = JSON.parse(data);
          if (pageScrollOffsetPercentage >= 99){
            this.setState({ enableNextStep: true });
          } 
          store.dispatch.button.enableButton({ btn: 'nextStepBtn', complete: this.state.enableNextStep });
        },
        loadedIntake: () => {},
        webviewReady: () => {},
      };
      this.loadForm();
      store.dispatch.mixpanel.addMetadata({ intakeFormsCheckin: true });
    };
    const { findings } = this.props;
    const fields = [
      { map: 'checkInIds', field: 'appointment_id' },
      { map: 'checkInIds', field: 'patient_id' },
      { map: 'checkInIds', field: 'practice_id' },
      { map: 'additionalInfo', field: 'providerName' },
      { map: 'additionalInfo', field: 'appointmentStartTime' },
      { map: 'additionalInfo', field: 'addressLine1' },
      { map: 'additionalInfo', field: 'addressLine2' },
      { map: 'additionalInfo', field: 'addressLine3' },
      { map: 'additionalInfo', field: 'city' },
      { map: 'additionalInfo', field: 'state' },
      { map: 'additionalInfo', field: 'zipCode' },
      { map: 'additionalInfo', field: 'phoneNumber' },
      { map: 'additionalInfo', field: 'locationName' },
    ];
    const finalData = fields.map(({ map, field }) => ({
      value: store.getState().appointmentCheckIn[field],
      formMapping: `${map}.${field.replace('_i', 'I')}`,
    }));
    if(store.getState().guest?.guestCheckinData?.isGuest) {
      finalData.push({ 
        value: store.getState().guest?.guestCheckinData?.appointment_id, 
        formMapping: 'checkInIds.appointmentId',
      })
      finalData.push({ 
        value: store.getState().guest?.guestCheckinData?.patient_id, 
        formMapping: 'checkInIds.patientId',
      })
      finalData.push({ 
        value: store.getState().guest?.guestCheckinData?.practice_id, 
        formMapping: 'checkInIds.practiceId',
      })
      finalData.push({ 
        value: store.getState().guest?.guestCheckinData?.username, 
        formMapping: 'checkInIds.username',
      })
      finalData.push({ 
        value: store.getState().guest?.guestCheckinData?.practice_mgmt, 
        formMapping: 'checkInIds.practiceMgmt',
      })
      finalData.push({ 
        value: store.getState().guest?.guestCheckinData?.user_id, 
        formMapping: 'checkInIds.user_id',
      })
      finalData.push({ 
        value: store.getState().guest?.guestCheckinData?.isGuest, 
        formMapping: 'checkInIds.is_guest_checkin',
      })
    }
    finalData.push({ value: findings.uuid, formMapping: 'checkInIds.findingsId' });
    store.dispatch.form.addExternalSubmissionData(finalData);
    store.dispatch.appointmentCheckIn.storeFindings(findings.uuid);
  }
  /* eslint-disable */
  get ifr() {
    return document.getElementsByTagName('iframe')[0];
  }

  onClick = _ => {
    this.setState({ enableNextStep: false });
    store.dispatch.loader.persistLoader({ persist: true });
    this.getIframeFunction('save_intake')();
  };

  getIframeFunction = name =>
    this.ifr.contentWindow.angular
      .element(this.ifr.contentWindow.document.body.getElementsByClassName('intakes'))
      .scope()[name];

  savedIntake = data => {
    const { forms, formIndex } = this.state;
    const nextFormIndex = formIndex + 1;
    this.setState(
      {
        forms: forms.concat(JSON.parse(data)),
        formIndex: nextFormIndex,
      },
      () => this.continue({ nextFormIndex }),
    );
  };
  submitForm = async _ => {
    const { client } = this.props;
    let externalSubmissionData = {};
    store.getState().form.externalSubmissionData.forEach(({ formMapping, value }) => {
      externalSubmissionData = set(externalSubmissionData, formMapping, value);
    });

    const { data } = await client.mutate({
      mutation: intakeForms,
      variables: { input: { ...externalSubmissionData, intakeForms: this.state.forms } },
    });
    store.dispatch.appointmentCheckIn.resetState();
    store.dispatch.mixpanel.addMetadata({ formComplete: { total: this.state.forms.length, type: 'intake_forms' } });
    store.dispatch.loader.persistLoader({ persist: false });
    if (get(data, 'postCheckInIntakeForms.submissionResult.success')) {
      this.mixpanelSuccessfulCheckIn();
      store.dispatch.appointmentCheckIn.storeRedirectPath(data.postCheckInIntakeForms.submissionResult.redirect);
      if(store.getState().guest.guestCheckinData.isGuest) {
        store.dispatch.guest.setGuestSuccessData({
          ...this.props.guestSuccessPageData,
          workflow: 'appointments',
        });
        this.props.history.push('/guest/workflow/appointments/add_appointment_success');
      } else this.props.history.push('/appointments');
    } else if (get(data, 'postCheckInIntakeForms')) {
      store.dispatch.appointmentCheckIn.fetchStart(data.postCheckInIntakeForms);
    }
    if (get(data, 'postCheckInIntakeForms.checkInPayment')) this.mixpanelSuccessfulCheckIn();
  };

  mixpanelSuccessfulCheckIn = _ => {
    store.dispatch.mixpanel.addMetadata({ successRedirect: true });
    store.dispatch.mixpanel.addMetadata({
      intakeFormsCheckinComplete: true,
      intakeFormsCount: this.state.forms.length,
    });
  };

  successRedirect = _ => {
    const {
      appointmentCheckIn: { path },
    } = this.props;
    store.dispatch.appointments.resetState();
    path ? this.props.history.push(`/${path}`) : this.props.history.push('/appointments');
  };

  continue = ({ nextFormIndex }) => {
    if (nextFormIndex < this.props.forms.length) return this.loadForm();
    this.submitForm();
  };

  loadForm = _ => {
    const { forms, findings } = this.props;
    this.getIframeFunction('load_intake')({ formData: forms[this.state.formIndex], userData: findings });
    this.ifr.contentWindow.scrollTo(0, 0);
    setTimeout(() => store.dispatch.loader.persistLoader({ persist: false }), 1000);
  };

  render() {
    const { leftSide, heading, footer } = this.props;
    return (
      <IntakeFormContext.Provider
        value={{
          continue: this.onClick,
          successRedirect: this.successRedirect,
        }}
      >
        <div>
          <SchemaParser schema={heading} />
          <div className={styles.wrapper}>
            <div className={styles.leftSide}>
              <SchemaParser schema={leftSide} />
            </div>
            <iframe
              style={{ height: 'calc(100vh - 4.5rem - 54px)', flex: 3, border: 0 }}
              src="/intake-forms/index.html"
              title="intakeForms"
            />
            <SchemaParser schema={footer} />
          </div>
        </div>
      </IntakeFormContext.Provider>
    );
  }
}

export default IntakeFormContainer;
