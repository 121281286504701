import LogRocket from 'logrocket';
import React, { Component } from 'react';
import { compose, isEmpty } from '@carecloud/cloudpak';
import { graphql, withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { GetLayout } from '../../graphql/queries';
import { Layout } from './Layout';
import { parseResult } from '../../graphql/utilities';
import { store } from '../../models';
import { ContextProvider } from '../root/TopLevelContext';
import Environment from '../../graphql/Environment';

class LayoutContainer extends Component {
  static contextType = ContextProvider;
  constructor(props) {
    super(props);
    this.state = {
      isSmallScreen: false,
    };
    this.checkScreenSize = this.checkScreenSize.bind(this);
    //Timer
    this.state = {
      currentTime: this.getCurrentTime(),
    };
  }
  componentDidMount() {
    this.context._setActions({
      signOut: this.signOut,
      changeLanguage: this.changeLanguage,
      changePractice: this.changePractice,
    });
    this.checkScreenSize();
    window.addEventListener('resize', this.checkScreenSize);
     // Update the timer every second (1000 milliseconds)
     this.timerID = setInterval(() => this.tick(), 1000);
  }
  componentWillReceiveProps({ layout, getLayout, fetchStart }) {
    if (layout.refetch && !getLayout.loading) {
      store.dispatch.loader.activateLoader({ loading: true });
      this.refetch({ getLayout });
      store.dispatch.layout.didRefetch();
      return;
    }
    if (isEmpty(layout.layoutScreen) && !getLayout.loading) {
      parseResult({ query: getLayout, dataField: 'getLayout', resultHandler: fetchStart });
      if (sessionStorage.getItem('logRocketInitiated')) {
        LogRocket.identify(sessionStorage.getItem('CC-Breeze-Web-username'), {
          name: sessionStorage.getItem('CC-Breeze-Web-username'),
          email: sessionStorage.getItem('CC-Breeze-Web-username'),
          subscriptionType: 'pro',
        });
      }
      //changes for ONC certifiaction
      if (getLayout?.getLayout?.data?.practice_patient_ids?.[0]?.practice_mgmt === 'talkehr') {
        if (getLayout?.getLayout?.data?.delegate !== undefined) {
          sessionStorage.setItem('Patient-Ids-List', JSON.stringify(getLayout.getLayout.data.delegate.links));
        } else {
          sessionStorage.setItem('Patient-Ids-List', JSON.stringify(getLayout.getLayout.data.practice_patient_ids));
        }
      }
      else{
        sessionStorage.setItem('Patient-Ids-List', JSON.stringify(getLayout.getLayout.data.practice_patient_ids));
      }
    }
  }
  componentWillUnmount() {
    // Remove the resize event listener to prevent memory leaks
    window.removeEventListener('resize', this.checkScreenSize);
    clearInterval(this.timerID);
  }
  getCurrentTime = () => {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  }
  signOut = _ => {
    sessionStorage.clear();
    store.dispatch({ type: 'RESET' });
    this.props.history.push('/');
  };

  changeLanguage = language => {
    if (localStorage.getItem('CC-Breeze-Web-language') === language) {
      this.props.openDropdown('languageMenu');
      return;
    }
    localStorage.setItem('CC-Breeze-Web-language', language);
    window.location.reload();
  };
  changePractice = practice => {
      let executionValue = "";
      if (practice.cloverapi === true) {
        executionValue = "cloverapi";
      } else if (practice.payeezy === true) {
        executionValue = "payeezy";
      }else{
        executionValue = 'no-value'
      }
    if (
      !sessionStorage.getItem('practiceSelectedName') &&
      store.getState().layout?.metaData?.practiceInformation?.[0]?.practice_name === practice.practice_name
    ) {
      store.dispatch.dropdown.toggleOpen('practiceSelectDropdown');
      return;
    }
    if (sessionStorage.getItem('practiceSelectedName') === practice.practice_name) {
      store.dispatch.dropdown.toggleOpen('practiceSelectDropdown');
      return;
    }
    sessionStorage.setItem('practiceSelected', practice.practice_id);
    sessionStorage.setItem('practiceSelectedName', practice.practice_name);
    sessionStorage.setItem('practiceSelectedMgmt', practice.practice_mgmt);
    sessionStorage.setItem('execution', executionValue);
    store.dispatch.dropdown.toggleOpen('practiceSelectDropdown');
    window.location.reload();
  };
  localhostCheck = _ => {
    if (Environment.get('BREEZE_API_GATEWAY') === 'http://localhost:4000' && document.getElementById('navbar')) {
      // document.getElementById('navbar').style.backgroundColor = 'black';
    }
  };
  refetch = async ({ getLayout }) => {
    const { data } = await getLayout.refetch();
    store.dispatch.layout.fetchStart({ layoutScreen: {} });
    store.dispatch.layout.fetchStart({ layoutScreen: data.getLayout.layoutScreen });
    store.dispatch.loader.persistLoader({ persist: false });
  };
  checkScreenSize() {
    this.setState({
      isSmallScreen: window.innerWidth <= 567,
    });
  }

  tick() {
    this.setState({
      currentTime: this.getCurrentTime(),
    });
  }
  render() {
    if (process.env.NODE_ENV !== 'production') this.localhostCheck();
    return (
      <Layout
        props={{
          ...this.props,
          layoutScreen: this.props.layout.layoutScreen,
          loading: this.props.loader.loading,
          persist: this.props.loader.persist,
          isSmallScreen: this.state.isSmallScreen,
          currentTime: this.state.currentTime,
        }}
      />
    );
  }
}

const mapStateToProps = ({ layout, loader, dropdown }) => ({ layout, loader, dropdown });
const mapDispatchToProps = ({ layout, dropdown }) => ({
  fetchStart: layout.fetchStart,
  openDropdown: dropdown.toggleOpen,
});
const enhancer = compose(
  withApollo,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  graphql(GetLayout, { name: 'getLayout' }),
);

export default enhancer(withRouter(LayoutContainer));
