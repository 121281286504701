import { compose, compact, get, isEmpty, isMatch, set, isArray } from '@carecloud/cloudpak';

import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import { store } from '../../../models';
import * as Mutations from '../../../graphql/mutations';
import * as Queries from '../../../graphql/queries';
import { Form } from './Form';
import { Session } from '../../services';
import { parseParams } from '../../services/routing';
// import { isMobileDevice } from '../../services/utilities';

class FormContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ids: get(props, 'props.formGroups', []).map(formGroup => formGroup.id),
      complete: false,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      props: {
        id,
        formGroup,
        formGroup: { formShown },
        notifyComplete,
        form: { submit },
        resetOnRemoval,
      },
    } = this.props;
    if (
      Object.keys(store.getState().guestEntry.rescheduleAppointmentData).length === 0 &&
      formShown === 'guestRescheduleAppointment'
    ) {
      const GuestReschedulePrevPropsParams = {
        practice_id: parseParams(prevProps.props).practiceId,
        patient_id: parseParams(prevProps.props).patientId,
        practice_mgmt: parseParams(prevProps.props).practiceMgmt,
        user_id: parseParams(prevProps.props).userId,
        username: parseParams(prevProps.props).username,
        location_ids: parseParams(prevProps.props).locationIds,
        resource_ids: parseParams(prevProps.props).resourceIds,
        visit_reason_id: parseParams(prevProps.props).visitReasonId,
        start_date: parseParams(prevProps.props).startDate,
        end_date: parseParams(prevProps.props).endDate,
      };
      this.GuestRescheduleAppointmentDataHandle(GuestReschedulePrevPropsParams);
    }
    if (resetOnRemoval && formShown !== id && this.props.props.formGroup.formShown === id) {
      store.dispatch.inputDropdown.resetValues();
    }
    if (submit && !prevProps.props.form.submit && id === formShown) {
      store.dispatch.form.toggleSubmit();
      this.submitForm();
    }
    const complete = this.state.ids.every(id => formGroup[id]);
    if (this.state.complete !== complete) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ complete });
    }
    if (!isEmpty(notifyComplete) && id === formShown) {
      const { model, trigger, param } = notifyComplete;
      if (this.props.props[model][param] !== complete) {
        store.dispatch[model][trigger]({ btn: param, complete });
      }
    }
  }
  GuestRescheduleAppointmentDataHandle = params => {
    store.dispatch.guestEntry.setRescheduleAppointmentData(params);
  };
  updateForm = fields => {
    const {
      // eslint-disable-next-line object-curly-newline
      props: { form },
    } = this.props;
    if (!isMatch(form.fields, fields)) {
      store.dispatch.form.updateForm(fields);
    }
  };
  endPolling = _ => {
    store.dispatch.button.polling({});
    store.dispatch.modal.toggleClose();
    store.dispatch.inputDropdown.resetValues();
  };
  poll = async ({ jobId = 0, query = '', dataField = '', documentPdf = null, count = 0 }) => {
    if (count > 100) return store.dispatch.button.polling({});
    const params = store.getState().button.param;
    if (documentPdf !== null) {
      if (params === 'ViewCCDA_CC_TBI') {
        const mimeType = 'application/pdf';
        const base64String = documentPdf?.data;
        const decodedString = atob(base64String);
        const arrayBuffer = new ArrayBuffer(decodedString.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < decodedString.length; i++) {
          uint8Array[i] = decodedString.charCodeAt(i);
        }
        const blob = new Blob([arrayBuffer], { type: mimeType });
        const fileURL = URL.createObjectURL(blob);
        window.open(fileURL, '_blank');
        URL.revokeObjectURL(fileURL);
        this.endPolling();
      } else {
        const downloadLink = document.createElement('a');
        const type = `:application/pdf;base64`;
        downloadLink.href = `data${type},${documentPdf?.data}`;
        downloadLink.download = `${documentPdf.fileName}`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        setTimeout(downloadLink.remove, 100);
        setTimeout(_ => {
          this.endPolling();
        }, 1500);
      }
    } else {
      const { data } = await this.props.client.query({
        query: Queries[query],
        variables: { input: { jobId } },
      });
      if (data[dataField]?.error) {
        store.dispatch.toast.open({
          type: 'error',
          message: data[dataField].errorMessage,
        });
        return this.endPolling();
      }

      if (data[dataField]?.documentRequest) {
        if (params === 'ViewCCDA_CC_TBI') {
          store.dispatch.loader.persistLoader({ persist: true });
          const mimeType = 'application/pdf';
          const decodedString = atob(data?.[dataField]?.data);
          const arrayBuffer = new ArrayBuffer(decodedString.length);
          const uint8Array = new Uint8Array(arrayBuffer);
          for (let i = 0; i < decodedString.length; i++) {
            uint8Array[i] = decodedString.charCodeAt(i);
          }
          const blob = new Blob([arrayBuffer], { type: mimeType });
          const fileURL = URL.createObjectURL(blob);
          window.open(fileURL, '_blank');
          URL.revokeObjectURL(fileURL);
          setTimeout(_ => {
            this.endPolling();
          }, 1500);
          store.dispatch.loader.persistLoader({ persist: false });
        } else {
          const downloadLink = document.createElement('a');
          const { fileType, plainText } = data[dataField];
          const type = `${plainText ? ':text/plain;charset=UTF-8' : `:application/${fileType};base64`}`;
          downloadLink.href = `data${type},${data?.[dataField]?.data}`;
          downloadLink.download = `${dataField}.${fileType}`;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
          setTimeout(downloadLink.remove, 100);
          setTimeout(_ => {
            this.endPolling();
          }, 1500);
        }
      } else if (get(data[dataField], 'data.visit_summary.visit_summary_request.status_code') === 'C') {
        window.location = get(
          data[dataField],
          'data.visit_summary.visit_summary_request.ccda_request.ccda_request.document_url',
        );
        setTimeout(_ => {
          this.endPolling();
        }, 1500);
      } else {
        setTimeout(_ => this.poll({ jobId, query, dataField, documentPdf, count: count + 1 }), 1000);
      }
    }
  };
  submitForm = _ => {
    store.dispatch.loader.persistLoader({ persist: true });
    this.submit();
  };
  submit = async () => {
    const {
      props: {
        signup: { signupScreen: [{ is_sms = false } = {}] = [] } = {},
        history,
        id,
        form: { fields, externalSubmissionData },
        formGroup: { events, deleted, formShown },
        contentRefresh,
        hasExternalSubmissionData,
        submitWholeForm,
        resetOnRemoval,
        redirect,
        addCurrentParams,
      },
    } = this.props;
    try {
      let inputObject = {};
      let arrayPaths = [];
      Object.keys(fields).forEach(field => {
        let found;
        if (submitWholeForm) {
          const foundEvents = events.filter(event => event.id === field);
          found = foundEvents.length > 1 ? foundEvents.find(event => !event.isInitial) : foundEvents[0];
        } else {
          found = events.find(event => event.id === field && !event.isInitial);
        }
        if (!found || !found.formMapping) return;
        inputObject = set(inputObject, found.formMapping, found.value, {
          // eslint-disable-next-line object-curly-newline
          mutate: true,
        });
        if (found.formMapping.match(/(\[[\d]+\])/)) {
          arrayPaths.push(found.formMapping.split(/(\[[\d]+\])/)[0]);
        }
      });
      deleted.forEach(formMapping => {
        inputObject = set(inputObject, formMapping, true, { mutate: true });
      });
      arrayPaths = arrayPaths.filter((value, index, arr) => arr.indexOf(value) === index);
      arrayPaths.forEach(path => {
        let value = get(inputObject, path);
        value = compact(value);
        inputObject = set(inputObject, path, value, { mutate: true });
      });
      if (hasExternalSubmissionData) {
        externalSubmissionData.forEach(data => {
          inputObject = set(inputObject, data.formMapping, data.value, {
            // eslint-disable-next-line object-curly-newline
            mutate: true,
          });
        });
      }
      if (addCurrentParams) {
        inputObject = { ...inputObject, ...parseParams(this.props.props) };
      }
      const newInputObject = Object.keys(inputObject).reduce((acc, key) => {
        const value = inputObject[key];
        acc[key] = value;
        if (isArray(value)) {
          acc[key] = inputObject[key].filter(item => !isEmpty(item));
        }
        return acc;
      }, {});
      if (is_sms) newInputObject.signupIsSMS = 'true';
      if (is_sms) {
        newInputObject.signupInputEmailEditable = get(fields, 'signupInputEmailEditable', String());
      }
      const practiceMgmt = JSON.parse(sessionStorage.getItem('Patient-Ids-List'))?.[0]?.practice_mgmt;
      const params = store.getState().button.param;
      const ViewButton = 'viewVertexCCDA';
      const exportButton = 'exportVertexCCDA';
      const downloadButton = 'downloadVertexCCDA';
      if (practiceMgmt === 'vertex') {
        let patientId;
        const totalPractice = JSON.parse(sessionStorage.getItem('totalPractice'));
        totalPractice === 1
          ? (patientId = JSON.parse(sessionStorage.getItem('Patient-Ids-List')).filter(
              x => x.practice_id === JSON.parse(sessionStorage.getItem('singleSelectedpractice')),
            )?.[0]?.patient_id)
          : (patientId = JSON.parse(sessionStorage.getItem('Patient-Ids-List')).filter(
              x => x.practice_id === sessionStorage.getItem('practiceSelected'),
            )?.[0]?.patient_id);
        if (params === exportButton || params === downloadButton || params === ViewButton) {
          const {
            practiceMgmt,
            visitSummaryEmail,
            visitSummaryEmailUnsecured,
            document_source: { document_source_id },
          } = newInputObject;
          let visitSummaryEmailUnsecure;
          if (visitSummaryEmailUnsecured) {
            visitSummaryEmailUnsecure = true;
          } else {
            visitSummaryEmailUnsecure = false;
          }
          const inputs =
            params !== exportButton
              ? {
                  document_source_id,
                  practiceMgmt,
                  type: params === ViewButton ? 'V' : 'D',
                  patientId,
                  practiceId: store.getState().form.Field.createVisitSummaryPractice,
                }
              : {
                  document_source_id,
                  practiceMgmt,
                  type: params === ViewButton ? 'V' : 'D',
                  visitSummaryEmail,
                  visitSummaryEmailUnsecured: visitSummaryEmailUnsecure,
                  patientId,
                  practiceId: store.getState().form.Field.createVisitSummaryPractice,
                };
          const Documents = await this.props.client.query({
            query: Queries.DownLoadvertexVisitSummary,
            variables: { input: inputs },
          });
          if (Documents && params !== exportButton) {
            try {
              const filename = Documents.data.downLoadvertexVisitSummary?.[0]?.name;
              if (params === ViewButton) {
                const mimeType = 'text/html';
                const base64String = Documents.data.downLoadvertexVisitSummary?.[0]?.document_data;
                const decodedString = atob(base64String);
                const blob = new Blob([decodedString], {
                  type: mimeType,
                });
                const fileURL = URL.createObjectURL(blob);
                const newTab = window.open(fileURL, '_blank');
                if (!newTab) {
                  alert('Please allow pop-ups for this site to view the file.');
                } else {
                  newTab.focus();
                }
                URL.revokeObjectURL(fileURL);
              } else {
                const downloadLink = document.createElement('a');
                downloadLink.href = `data:application/xml;base64,${Documents.data.downLoadvertexVisitSummary?.[0]?.document_data}`;
                downloadLink.download = filename;
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
              }
            } catch (error) {
              console.error('Error downloading file:', error);
            }
            store.dispatch.loader.persistLoader({ persist: false });
            store.dispatch.button.polling({});
          } else if (Documents) {
            store.dispatch.loader.persistLoader({ persist: false });
            store.dispatch.button.polling({});
          }
        } else {
          const {
            practiceMgmt,
            createVisitSummaryDateFrom,
            createVisitSummaryDateTo,
            createVisitSummaryPractice,
          } = newInputObject;
          const inputs = {
            createVisitSummaryDateFrom,
            practiceMgmt,
            createVisitSummaryDateTo,
            createVisitSummaryPractice,
            patientId,
          };
          store.dispatch.form.setFields(inputs);
          const data = await this.props.client.query({
            query: Queries.VertexCreateVisitSummaryQuery,
            variables: { input: inputs },
          });
          const {
            data: {
              vertexCreateVisitSummary: {
                payload: { ccda },
              },
            },
          } = data;
          const CCDALIST = ccda.map(document => ({
            document_format: document.document_format,
            document_handler: document.document_handler,
            document_source_id: document.document_source_id,
            document_type: document.document_type,
            name: document.name,
            created_at: document.created_at,
          }));
          const Myhealth = await this.props.client.query({
            query: Queries.GetMyHealth,
            variables: { input: { CCDALIST } },
          });
          if (data && Myhealth) {
            //Commenting this code probably it will be used next time
            // const MyhealthSchema = { ...Myhealth };
            // const UpdatedSchema = { ...MyhealthSchema };
            // UpdatedSchema.data.getMyHealth.myHealthScreen[0].content[0].formGroups[0].groups[0].fields[0].row[0].value = store.getState().form.Field.createVisitSummaryPractice;
            // UpdatedSchema.data.getMyHealth.myHealthScreen[0].content[0].formGroups[0].groups[0].fields[1].row[0].placeholder = store.getState().form.Field.createVisitSummaryDateFrom;
            // UpdatedSchema.data.getMyHealth.myHealthScreen[0].content[0].formGroups[0].groups[0].fields[2].row[0].placeholder = store.getState().form.Field.createVisitSummaryDateTo;
            const {
              data: {
                getMyHealth: { myHealthScreen },
              },
            } = Myhealth;
            store.dispatch.myHealth.fetchStart({ myHealthScreen });
            store.dispatch.modal.toggleClose();
            const { radioInputCancellationReason } = this.props;
            const {
              radioInput: { resetRadioInput } = {},
              formGroup: { resetEvents } = {},
              inputDropdown: { resetValues } = {},
            } = store.dispatch;
            setTimeout(() => {
              radioInputCancellationReason && resetRadioInput() && resetEvents();
              store.getState().inputDropdown.createVisitSummaryPractice && resetValues();
            }, 300);
            store.dispatch.modal.toggleOpen('createVisitSummaryModal');
            store.dispatch.formGroup.showForm('vertexCreateVisitSummary');
            store.dispatch.loader.persistLoader({ persist: false });
            store.dispatch.button.polling({});
          }
        }
        }else{
        const  {data} = await this.props.client.mutate({
            // Calls the mutation based on form's id
            mutation: Mutations[id],
            // Dynamically inserts form field values as variables
            variables: { input: newInputObject },
          });
      if (!data) return;
      if (data[id] && data[id].polling) this.poll(data[id].polling);
      if (data[Object.keys(data)[0]] === null) throw new Error(data);
      if (data.changeEmail && data.changeEmail.authenticationToken) {
        sessionStorage.setItem('CC-Breeze-Web-authenticationToken', data.changeEmail.authenticationToken);
      }
      // If data returned is for signup, store the necessary data to auto-login the new user
      // if (data.createNewUser) {
      //   if (data.createNewUser.error) {
      //     store.dispatch.loader.persistLoader({ persist: false });
      //     store.dispatch.modal.saveCustomMessage(data.createNewUser.message);
      //     return store.dispatch.modal.toggleOpen('registerErrorModal');
      //   }
      //   const submissionData = data.createNewUser.data || {};
      //   if (sms === 'true') {
      //     if (!submissionData.redirect) return;
      //     store.dispatch.mixpanel.addMetadata({ successRedirect: true });
      //     return this.props.props.history.push(submissionData.redirect);
      //   } else if (typeof window.orientation !== 'undefined' || navigator.userAgent.includes('IEMobile')) {
      //     // If user is on a mobile device, redirect them to the mobile login page and do not auto-login
      //     history.push('/?redirect=mobileSignup');
      //   } else {
      //     Session.newAccountSetSession({ data: data.createNewUser });
      //     history.push('/appointments');
      //   }
      // }
      // if (data.createAccount) {
      //   if (data.createAccount.error) {
      //     store.dispatch.loader.persistLoader({ persist: false });
      //     store.dispatch.modal.saveCustomMessage(data.createAccount.message);
      //     return store.dispatch.modal.toggleOpen('registerErrorModal');
      //   } else if (isMobileDevice() && has(data, 'createAccount.data.payload.create_account_existing_user.sign_in')) {
      //     history.push('/?confirmed=true');
      //     return;
      //   } else if (has(data, 'createAccount.data.payload.create_account_existing_user.sign_in')) {
      //     Session.newAccountSetSession({ data: data.createAccount });
      //     history.push('/appointments');
      //   }
      //   const { practiceId, usedBtn } = parseParams(this.props.props);
      //   store.dispatch.mixpanel.addMetadata({
      //     // eslint-disable-next-line object-curly-newline
      //     createAccount: { usedBtn, practiceId },
      //   });
      //   const { email } = data.createAccount;
      //   const {
      //     props: {
      //       login: {
      //         // eslint-disable-next-line object-curly-newline
      //         screens: { signupSuccess },
      //       },
      //     },
      //   } = this.props;
      //   store.dispatch.signup.storeEmail({ email });
      //   store.dispatch.signup.fetchStart({
      //     // eslint-disable-next-line object-curly-newline
      //     signupScreen: signupSuccess.signupScreen,
      //   });
      // }
      if (resetOnRemoval) {
        store.dispatch.inputDropdown.resetValues();
      }
      contentRefresh.forEach(model => {
        store.dispatch[model].resetState();
      });
      if (redirect) {
        if(formShown === 'guestRescheduleAppointment')
        {
          store.dispatch.guestEntry.setIsReschedule(true);
          history.push(`${redirect}`);
        }        
        const guestPayWf = formShown === 'guestPay' || formShown === 'guestPayNoCode';
        const guestCheckin = formShown === 'guestCheckin'
        let executionValue = "";
          if (data?.guestPayNoCode?.payload?.practice_information[0]?.cloverapi === true || data?.guestPay?.payload?.practice_information[0]?.cloverapi === true) {
            executionValue = "cloverapi";
          } else if (data?.guestPayNoCode?.payload?.practice_information[0]?.payeezy === true || data?.guestPay?.payload?.practice_information[0]?.payeezy === true) {
            executionValue = "payeezy";
          }else{
            executionValue = 'no-value'
          }
          sessionStorage.setItem('execution', executionValue);
        // handle error for forms that do not return a schema but redirect to a component
        if (data[formShown].caughtTooEarlyError) {
          store.dispatch.loader.persistLoader({ persist: false });
          return store.dispatch.modal.toggleOpen(`${formShown}ErrorModal`);
        }

        
        const patientId = data[formShown]?.payload?.patient?.id || String();
        const practiceId = data[formShown]?.payload?.practice_information?.[0]?.practice_id || String();
        const practiceMgmt = data[formShown]?.payload?.practice_information?.[0]?.practice_mgmt || String();
        const practiceName = guestCheckin ?  '' : data[formShown]?.payload?.practice_information[0]?.practice_name || String();
        sessionStorage.setItem("practiceManagement",practiceMgmt)
        Session.setSession({
          // eslint-disable-next-line object-curly-newline
          persistedGuestData: JSON.stringify(data[formShown]),
        });
        if(guestCheckin){
          // eslint-disable-next-line prefer-destructuring
          const { username } = parseParams(this.props.props);
          const guestCheckInPatientId =  data[formShown].payload.patient_app_signin.check_in.patient_id;
          const guestCheckInPracticeId =  data[formShown].payload.patient_app_signin.check_in.practice_id;
          const guestCheckInPracticeMgmt = data[formShown].payload.patient_app_signin.check_in.practice_mgmt;
          const guestCheckInAppointmentId = data[formShown].payload.patient_app_signin.check_in.appointment_id;
          const userId = data[formShown].payload.patient_app_signin.check_in.user_id;
          const isGuestCheckin = data[formShown].payload.patient_app_signin.check_in.is_guest_checkin;
          const { isGuest } = data[formShown].payload.patient_app_signin.check_in;

          if(!isEmpty(guestCheckInPatientId, guestCheckInPracticeId, guestCheckInPracticeMgmt, guestCheckInAppointmentId, userId, isGuest, isGuestCheckin)){
            store.dispatch.guest.setGuestCheckin({...data[formShown].payload.patient_app_signin.check_in, username, isGuest })

            let executionValue = "";
            if (data[formShown].payload.practice_information?.[0]?.cloverapi === true) {
              executionValue = "cloverapi";
            } else if (data[formShown].payload.practice_information?.[0]?.payeezy === true) {
              executionValue = "payeezy";
            } else {
              executionValue = 'no-value'
            }

            sessionStorage.setItem('execution', executionValue)
            store.dispatch.guestEntry.setPatientId(guestCheckInPatientId);
            history.push(`${redirect}?practice_id=${guestCheckInPracticeId}&practice_mgmt=${guestCheckInPracticeMgmt}&patient_id=${guestCheckInPatientId}&appointment_id=${guestCheckInAppointmentId}&is_guest_checkin=${isGuestCheckin}`)
          }
        } else {
          if (guestPayWf) {
            if (isEmpty(data[formShown].payload.patient_balances[0].pending_balances[0].payload)) {
              history.push(
                `/guest/guestAlreadyDone?done=pay&practice_id=${practiceId}&practice_mgmt=${practiceMgmt}&practice_name=${practiceName.replace(
                  / /g,
                  '_',
                )}`,
              );
              return store.dispatch.guestEntry.setReloadGuest(true);
            }
            store.dispatch.guest.setGuestPaymentData(data[formShown]);
          }

          store.dispatch.guestEntry.setPatientId(patientId);
          history.push(`${redirect}?practiceId=${practiceId}&practiceMgmt=${practiceMgmt}&patientId=${patientId}`);
        }
      }
      store.dispatch.loader.persistLoader({ persist: false });
      if (!isEmpty(data.submissionResult) && data.submissionResult.success) {
        store.dispatch.mixpanel.addMetadata({ successRedirect: true });
        store.dispatch[data.submissionResult.redirect] && store.dispatch[data.submissionResult.redirect].resetState();
        // this.props.props.history.push(data.submissionResult.redirect);
      }
      store.dispatch({
        type: 'toast/open',
        payload: data[Object.keys(data)[0]],
      });
      return true;
      }
    } catch (error) {
      store.dispatch.loader.persistLoader({ persist: false });
      
      if(formShown === 'guestCheckin')
        store.dispatch.clientSideModal.toggleOpen({id: "guestErrorModal", message: store.getState().error?.errors?.[0]?.message})
      
      console.error(error);
      return false;
    }
  };

  render() {
    return (
      <Form
        props={{
          ...this.props.props,
          classes: this.props.classes,
          completed: this.state.complete,
          formShown: this.props.props.formGroup.formShown,
          updateForm: this.updateForm,
        }}
      />
    );
  }
}

const enhancer = compose(withApollo);
export default enhancer(FormContainer);
