/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { compose, get } from '@carecloud/cloudpak';
import * as Mutations from '../../../graphql/mutations';
import { Form } from '../../rjsf';
import { store } from '../../../models';
import ContentHeader from './ContentHeader';
import CheckInAllergies from './Allergies';
import CheckInMedications from './Medications';
import { getIsCompleted as areMedicationsCompleted } from './Medications.reducer';
import { getIsCompleted as areAllergiesCompleted } from './Allergies.reducer';

const customFields = {
  CheckInAllergies,
  CheckInMedications,
};

const enableUpdateButton = complete =>
  store.dispatch.button.enableButton({
    btn: 'nextStepBtn',
    complete,
  });

const transformFormData = formData => ({
  ...formData,
  allergiesMedications: {
    ...(formData?.allergiesMedications?.allergies || {}),
    ...(formData?.allergiesMedications?.medications || {}),
  },
});

const validate = (formData, errors) => {
  const allergies = formData?.allergiesMedications?.allergies;
  if (allergies && !areAllergiesCompleted(allergies)) {
    errors.allergiesMedications.allergies.addError('');
  }

  const medications = formData?.allergiesMedications?.medications;
  if (medications && !areMedicationsCompleted(medications)) {
    errors.allergiesMedications.medications.addError('');
  }

  return errors;
};

export const Component = React.memo(({ client, formData, schema, submit, uiSchema, history }) => {
  const { fetchStart } = store.dispatch.appointmentCheckIn;
  //grab id's from store if needed for guest flow
  const [form, setForm] = useState({ formData });
  const isFormValid = !Array.isArray(form.errors) || form.errors.length < 1;
  const handleSubmit = async test => {
    store.dispatch.loader.show();
    enableUpdateButton(false);
    const { data } = await client.mutate({
      mutation: Mutations.medsAllergyPage,
      variables: {
        input: transformFormData(form.formData),
      },
    });
    if (get(data, 'postCheckInMedsAllergies.submissionResult.success')) {
      store.dispatch.form.toggleSubmit();
      store.dispatch.mixpanel.addMetadata({ successRedirect: true });
      store.dispatch.appointments.resetState();
      if(store.getState().guest.guestCheckinData.isGuest) {
        store.dispatch.guest.setGuestSuccessData({
          ...schema.guestSuccessPageData,
          workflow: 'appointments',
        });
        history.push('/guest/workflow/appointments/add_appointment_success');
      } else
      history.push('/appointments');

    } else if (data.postCheckInMedsAllergies !== null) {
      store.dispatch.form.toggleSubmit();
      fetchStart(data.postCheckInMedsAllergies);
    } else {
      store.dispatch.form.toggleSubmit();
      enableUpdateButton(true);
    }
    store.dispatch.loader.hide();
  };

  useEffect(() => {
    enableUpdateButton(isFormValid);
  }, [isFormValid]);

  if (submit) {
    handleSubmit();
  }

  return (
    <div id='medsAndAllergiesContainer'>
      <ContentHeader title={schema.title} description={schema.description} icon={isFormValid} />
      <Form
        customFields={customFields}
        formData={form.formData}
        onChange={setForm}
        schema={schema}
        uiSchema={uiSchema}
        validate={validate}
      />
    </div>
  );
});

const mapStateToProps = ({ form: { submit } }) => ({ submit });

export default compose(withApollo, connect(mapStateToProps))(Component);
